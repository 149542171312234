import React, { ChangeEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Card, ListGroup, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { GroceryCartItem } from './GroceryApi';
import './index.css'


interface GroceryCardProps {
    title: string
    groceryItems: Array<GroceryCartItem>
    removeItem: (item: GroceryCartItem) => void
    addItem: (item: GroceryCartItem) => void
}

interface GroceryCardState {
    show: boolean,
    newIngredientValue: string
}

export default class GroceryCard extends React.Component<GroceryCardProps, GroceryCardState> {
    constructor(props: GroceryCardProps) {
        super(props)
        this.state = {
            show: false,
            newIngredientValue: ""
        }
    }

    renderListGroupEntries(items: Array<GroceryCartItem>): Array<JSX.Element> {
        return items.map(item => {
            let key = item.recipe + item.ingredient
            if (item.retrieved) {
                return <ListGroup.Item key={key} className="strike">{item.ingredient}</ListGroup.Item>
            } else {
                return <ListGroup.Item key={key}>
                    <Button className="mr-2" variant="light" size="sm" onClick={() => this.props.removeItem(item)}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                        </svg>
                    </Button>
                    {item.ingredient}
                </ListGroup.Item>
            }
        })
    }

    hide = () => {
        this.setState({show: false})
    }

    showModal = () => {
        this.setState({show: true})
    }

    addItem = () => {
        let cartItem = new GroceryCartItem(this.props.title, this.state.newIngredientValue, false)
        this.props.addItem(cartItem)
        this.hide()
    }

    onChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            newIngredientValue: event.target.value
        })
    }

    render() {
        let listGroupEntries = this.renderListGroupEntries(this.props.groceryItems)
        
        return (
                <Card className="mb-2 mx-auto grocery-card" >
                    <Card.Body> 
                    <Card.Title>{this.props.title}</Card.Title>
                    <ListGroup className="list-group-flush">
                        {listGroupEntries}
                    </ListGroup>
                    <Button variant="outline-primary" size="sm" className="mr-2 mt-2" onClick={() => this.showModal()}>Add Item</Button>
                    </Card.Body>

                    <Modal show={this.state.show} onHide={this.hide} >
                        <Modal.Body>
                            <InputGroup className="mb-3">
                                <FormControl placeholder="Ingredient..." onChange={e => this.onChange(e as any)} />
                                <InputGroup.Append>
                                    <Button variant="outline-secondary" onClick={() => this.addItem()}>Add</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Modal.Body>
                    </Modal>
                </Card>
        )
    }
}

