import React, { ChangeEvent, FormEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Form, Row, Image } from 'react-bootstrap';
import { GroceryClient, Ingredient, Recipe } from './GroceryApi';
import * as GroceryApi from './GroceryApi'
import CropImageComponent from './CropImageComponent';
import IngredientRows from './IngredientRows';
import StringTable, { StringRow } from './StringTableComponent';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


interface RecipeEditorProps {
    user: string
    recipe: Recipe
    fetchData: boolean
    submit: (event: FormEvent<HTMLElement>, recipe: Recipe) => void
}

interface RecipeEditorState {
    recipe: Recipe,
    editorState: EditorState
}

export default class RecipeEditor extends React.Component<RecipeEditorProps, RecipeEditorState> {
    groceryClient: GroceryClient

    constructor(props: RecipeEditorProps) {
        super(props)
        this.groceryClient = new GroceryClient()

        let editorState = EditorState.createEmpty()
        if (props.recipe.instructions) {
            let contentBlock = htmlToDraft(props.recipe.instructions)
            if (contentBlock) {
                let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                editorState = EditorState.createWithContent(contentState)
            }
        }


        if (this.props.recipe) {
            this.state = {
                recipe: this.props.recipe,
                editorState: editorState
            }
        } else {
            // If no recipe is provided, then initialize with an empty one
            let emptyRecipe = GroceryApi.emptyRecipe()
            emptyRecipe.ingredients.push(GroceryApi.emptyIngredient())

            this.state = {
                recipe: emptyRecipe,
                editorState: EditorState.createEmpty()
            }
        }
    }

    componentDidMount() {
        if (this.props.fetchData) {
            console.log("Missing recipe, fetching the data...")
            this.groceryClient.get_recipe(this.props.recipe.uid).then(r => this.setState({recipe: r}))
        }
    }


    editorChange = (newEditorState: EditorState) => {
        let instructions = draftToHtml(convertToRaw(newEditorState.getCurrentContent()))
        console.log(instructions)
        let updatedRecipe = {...this.state.recipe}
        updatedRecipe.instructions = instructions
        this.setState({
            editorState: newEditorState,
            recipe: updatedRecipe
        })
    }

    // The submit logic is handled by the parent component
    handleSubmit = (event: FormEvent<HTMLElement>, recipe: Recipe) => {
        this.props.submit(event, this.state.recipe)
    }

    imageChange = (newImage: string) => {
        let updatedRecipe = { ...this.state.recipe }
        updatedRecipe.image = newImage
        this.setState({
            recipe: updatedRecipe
        })
    }

    titleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let updatedRecipe = { ...this.state.recipe }
        updatedRecipe.title = event.target.value
        this.setState({
            recipe: updatedRecipe
        })
    }

    subtitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let updatedRecipe = { ...this.state.recipe }
        updatedRecipe.subtitle = event.target.value
        this.setState({
            recipe: updatedRecipe
        })
    }


    ingredientChange = (ingredients: Array<Ingredient>) => {
        let updatedRecipe = { ...this.state.recipe }
        updatedRecipe.ingredients = ingredients
        this.setState({
            recipe: updatedRecipe
        })
    }

    tagChange = (rows: Array<StringRow>) => {
        let updatedRecipe = { ...this.state.recipe }
        updatedRecipe.tags = rows
        this.setState({
            recipe: updatedRecipe
        })
    }

    deleteImage = () => {
        let updatedRecipe = { ...this.state.recipe }
        updatedRecipe.image = ""
        this.setState({
            recipe: updatedRecipe
        })
    }


    renderImageSection() {
        if (this.state.recipe.image) {
            // We have an image, display it and offer to change it
            return (
                <React.Fragment>
                    <Row>
                        <Col>
                            {/* <Form.Label>Image</Form.Label> */}
                            <h4>Image</h4>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <Image src={this.state.recipe.image} />
                        </Col>
                    </Row>
                    <Row className="mb-3 mt-2">
                        <Col>
                            <Button onClick={() => this.deleteImage()}>Delete Image</Button>
                        </Col>
                    </Row>
                </React.Fragment>
            )
        } else {
            // No image yet, ask to upload one
            return (
                <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label>
                    <CropImageComponent croppedImage={(img: string) => this.imageChange(img)} />
                    <Image src={this.state.recipe.image} />
                </Form.Group>
            )
        }
    }


    render() {
        return (
            <Form onSubmit={e => this.handleSubmit(e, this.state.recipe)}>
            <Row>
                <Col>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column md="auto" sm="auto" className="pr-1">Author:</Form.Label>
                        <Col className="pl-0">
                            <Form.Control plaintext readOnly defaultValue={this.props.user} />
                        </Col>
                    </Form.Group>
                    {this.renderImageSection()}

                    <Form.Group controlId="title">
                        {/* <Form.Label>Title</Form.Label> */}
                        <h4>Title</h4>
                        <Form.Control required type="text"  placeholder="Mom's spaghetti" defaultValue={this.state.recipe.title} onChange={e => this.titleChange(e as any)} />
                    </Form.Group>
                    <Form.Group controlId="subtitle">
                        {/* <Form.Label>Sub-title</Form.Label> */}
                        <h4>Sub-title</h4>
                        <Form.Control required type="text" placeholder="Spaghetti the way mom makes it" defaultValue={this.state.recipe.subtitle} onChange={e => this.subtitleChange(e as any)} />
                    </Form.Group>

                    {/* Generalize the IngredientRows component to be text rows and use it for tags as well? */}
                    <Form.Group controlId="tags">
                        {/* <Form.Label>Tags</Form.Label> */}
                        <h4>Tags</h4>
                        <hr/>
                        {/* <Form.Control required type="text" placeholder="Lunch" defaultValue={this.state.recipe.tags} onChange={e => this.subtitleChange(e as any)} /> */}
                        <StringTable rows={this.state.recipe.tags} onChange={this.tagChange} />
                    </Form.Group>

                    <h4>Ingredients</h4>
                    <hr/>
                </Col>
            </Row>
            <IngredientRows ingredients={this.state.recipe.ingredients} onChange={this.ingredientChange} />
            <Row className="mt-3">
                <Col>
                <h4>Recipe</h4>
                <hr />
                </Col>
            </Row>
            <Row className="mt-1">
                <Col>
                    <Editor
                        editorState={this.state.editorState}
                        editorStyle={{ border: "1px solid grey", height: "500px", background: "white" }}
                        toolbarStyle={{ border: "1px solid grey" }}
                        onEditorStateChange={this.editorChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" className="mt-4" type="submit">
                        Save
                    </Button>
                </Col>
            </Row>
            </Form>
        )
    }
}