import { v4 as uuidv4 } from 'uuid';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { StringRow } from './StringTableComponent';


// ES6 maps dont serialize to JSON..... https://2ality.com/2015/08/es6-map-json.html
export class GroceryCartItem {
    recipe: string
    ingredient: string
    retrieved: boolean

    constructor(recipe: string, ingredient: string, retrieved: boolean) {
        this.recipe = recipe
        this.ingredient = ingredient
        this.retrieved = retrieved
    }
}

export class GroceryCart {
    groceries: Array<GroceryCartItem>

    constructor(groceries: Array<GroceryCartItem>) {
        this.groceries = groceries
    }

    addItems(items: Array<GroceryCartItem>): GroceryCart {
        return new GroceryCart(this.groceries.concat(items))
    }

    // toMap(): Map<string, Array<string>> {
    toMap(): Map<string, Array<GroceryCartItem>> {
        // Remove duplicates by converting to a Set
        let recipeNames: Set<string> = new Set(this.groceries.map(item => item.recipe))

        // ES6 is silly. Set doesn't have .map
        let recipeNamesArray = Array.from(recipeNames.values())

        return new Map(recipeNamesArray.map(name => {
            let items = this.groceries.filter(item => item.recipe === name)
            // let ingredients = items.map(item => item.ingredient)
            // return [name, ingredients]
            return [name, items]
        }))
    }
}


export interface Ingredient {
    uid: string
    ingredient: string | undefined
}

export class Recipe {
    uid: string
    author: string
    title: string
    subtitle: string
    image: string = ""
    tags: Array<StringRow> = []
    ingredients: Array<Ingredient>
    instructions: string

    constructor(uid: string, author: string, title: string, subtitle: string, image: string, ingredients: Array<Ingredient>, tags: Array<StringRow>, instructions: string) {
        this.uid = uid
        this.author = author
        this.title = title
        this.subtitle = subtitle
        this.image = image
        this.ingredients = ingredients
        this.tags = tags
        this.instructions = instructions
    }
}


export function parseRecipe(js: any): Recipe {
        let ingredients: Array<Ingredient> = js.ingredients.map((jsInner: any) => {
                return {
                    uid: jsInner.uid ? jsInner.uid : uuidv4(),
                    ingredient: jsInner.ingredient
                }
            }
        )
        let tags: Array<StringRow> = js.tags.map((jsInner: any) => {
                return {
                    uid: jsInner.uid ? jsInner.uid : uuidv4(),
                    value: jsInner.value
                }
            }
        )
        return new Recipe(
            js.uid,
            js.author,
            js.title,
            js.subtitle,
            js.image,
            ingredients,
            tags,
            js.instructions
        )
}

export function emptyCart(): GroceryCart {
    return new GroceryCart([])
}

export function emptyIngredient(): Ingredient {
    return {
        uid: uuidv4(),
        ingredient: ""
    }
}

export function emptyRecipe(): Recipe {
    return new Recipe(uuidv4(), "", "", "", "", [], [], "")
}

export class GroceryClient {
    private url: string 
    private getRecipePath: (uid: string) => string
    private getRecipesPath: string
    private putRecipePath: string
    private postRecipePath: (uid: string) => string

    private cartsPath: (user: string) => string


    constructor() {
        this.url = "https://cfs9ub7xf2.execute-api.us-east-2.amazonaws.com/RecipesDeployment"
        this.getRecipePath = (uid: string) => `${this.url}/recipes/${uid}`
        this.getRecipesPath = `${this.url}/recipes`
        this.putRecipePath = `${this.url}/recipes`
        this.postRecipePath = (uid: string) => `${this.url}/recipes/${uid}`

        this.cartsPath = (user: string) => `${this.url}/carts/${user}`
    }

    private parse_get_recipes(js: any): Array<Recipe> {
        let recipes: Array<Recipe> = js.Items.map( (item: any) => {
            let ingredients: Array<Ingredient> = item.ingredients.L.map((js: any) => {
                    return {
                        "ingredient": js.M.ingredient.S
                    }
                }
            )

            let tags: Array<StringRow> = item.tags.L.map((js: any) => {
                    return {
                        uid: js.M.uid.S,
                        value: js.M.value.S
                    }
                }
            )

            let instructions = ""
            if (item.instructions) {
                instructions = item.instructions.S
            }

            return new Recipe(
                item.uid.S,
                item.author.S,
                item.title.S,
                item.subtitle.S,
                item.image.S,
                ingredients,
                tags,
                instructions
            )
        })
        return recipes
    }

    private parse_get_recipe(js: any): Recipe {
        let item = js.Item

        let ingredients: Array<Ingredient> = item.ingredients.map((js: any) => {
                return {
                    "ingredient": js.ingredient
                }
            }
        )
        return new Recipe(
            item.uid,
            item.author,
            item.title,
            item.subtitle,
            item.image,
            ingredients,
            item.tags,
            item.instructions
        )
    }

    private parse_get_cart(js: any): GroceryCart {
        let groceryItems = js.Item.groceries.map((jsItem: any) => {
            return new GroceryCartItem(jsItem.recipe, jsItem.ingredient, jsItem.retrieved)
        })
        return new GroceryCart(groceryItems)
    }

    get_cart(jwtToken: string, user: string): Promise<GroceryCart> {
        return fetch(this.cartsPath(user), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': jwtToken
            } 
        }).then(res => res.json())
          .then(js => this.parse_get_cart(js))
    }

    post_cart(jwtToken: string, user: string, cart: GroceryCart): Promise<boolean> {
        return fetch(this.cartsPath(user), {
            method: 'POST',
            body: JSON.stringify(cart),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': jwtToken
            } 
        }).then( resp => {
            if (resp.ok) {
                return true
            } else {
                console.log(`Error: Failed to put recipe. Response body=${resp.body}`)
                return false
            }
        })
    }

    get_recipes(): Promise<Array<Recipe>> {
        return fetch(this.getRecipesPath)
          .then(res => res.json())
          .then(js => this.parse_get_recipes(js))
    }

    get_recipe(uid: string): Promise<Recipe> {
        let path = this.getRecipePath(uid)
        return fetch(path)
            .then(res => res.json())
            .then(js => this.parse_get_recipe(js))
    }

    /**
     * 
     * This is calling POST /recipes, which will create a new one
     * 
     * @param jwtToken This is a CognitoUserSession jwtToken. This can be retreived by calling Auth.currentSession()
     * then session.getIdToken().getJwtToken()
     */
    put_recipe(jwtToken: string, recipe: Recipe): Promise<boolean> {
        return fetch(this.putRecipePath, {
            method: 'POST',
            body: JSON.stringify(recipe),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': jwtToken
            } 
        }).then( resp => {
            if (resp.ok) {
                return true
            } else {
                console.log(`Error: Failed to put recipe. Response body=${resp.body}`)
                return false
            }
        })
    }

    /**
     * This is calling POST /recipes/{uid}, which will update a recipe
     * 
     * @param jwtToken This is a CognitoUserSession jwtToken. This can be retreived by calling Auth.currentSession()
     * then session.getIdToken().getJwtToken()
     */
    post_recipe(jwtToken: string, recipe: Recipe): Promise<boolean> {
        return fetch(this.postRecipePath(recipe.uid), {
            method: 'POST',
            body: JSON.stringify(recipe),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': jwtToken
            } 
        }).then( resp => {
            if (resp.ok) {
                return true
            } else {
                console.log(`Error: Failed to put recipe. Response body=${resp.body}`)
                return false
            }
        })
    }



    saveCart(user: string, cart: GroceryCart): Promise<boolean> {
        // We should be able to ask Auth who the user is
        return Auth.currentSession().then((u: CognitoUserSession) => {
            return u.getIdToken().getJwtToken()
        }).then(jwtToken => {
            return this.post_cart(jwtToken, user, cart)
        })
    }

    getCart(user: string): Promise<GroceryCart> {
        return Auth.currentSession().then((u: CognitoUserSession) => {
            return u.getIdToken().getJwtToken()
        }).then(jwtToken => {
            return this.get_cart(jwtToken, user)
        })
    }

    addToCart(user: string, items: Array<GroceryCartItem>): Promise<boolean> {
        // TODO: We fetch 2apis to add to the cart. This doubles the time it takes to save items into a cart
        // Create a new lambda function that does this
        return this.getCart(user).then((cart: GroceryCart) => {
            let newCart = cart.addItems(items)
            return this.saveCart(user, newCart)
        })
    }
}