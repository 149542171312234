import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Button, Jumbotron } from 'react-bootstrap';
import { GroceryClient, GroceryCart, GroceryCartItem } from './GroceryApi';
import * as GroceryApi from './GroceryApi'
import GroceryCard from './GroceryCard';

interface CartProps {
    user?: string
}
interface CartState { 
    cart: GroceryCart
    showChangesButton: boolean
    fetchingCart: boolean
}

export default class CartPage extends React.Component<CartProps, CartState> {
    groceryClient: GroceryClient

    constructor(props: CartProps) {
        super(props)
        this.groceryClient = new GroceryClient()
        this.state = {
            cart: GroceryApi.emptyCart(),
            showChangesButton: false,
            fetchingCart: true
        }
    }

    componentDidMount() {
        this.groceryClient.getCart(this.props.user as string).then((cart: GroceryCart) => {
            this.setState({
                cart: cart,
                fetchingCart: false
            })
        }).catch(err => { console.log(`"Error retrieving cart from server`, err) })
    }

    removeItem = (itemToRemove: GroceryCartItem) => {
        let items = this.state.cart.groceries.map(item => {
            if (item.recipe === itemToRemove.recipe && item.ingredient === itemToRemove.ingredient) {
                return new GroceryCartItem(item.recipe, item.ingredient, true)
            } else {
                return item
            }
        })

        let newCart = new GroceryCart(items)

        this.setState({
            cart: newCart,
            showChangesButton: true
        })
    }

    addItem = (itemToAdd: GroceryCartItem) => {
        let newItems = this.state.cart.groceries.concat(itemToAdd)
        let newCart = new GroceryCart(newItems)

        this.setState({
            cart: newCart,
            showChangesButton: true
        })
    }

    renderCards(cart: GroceryCart): Array<JSX.Element> {
        let map = cart.toMap()

        if (!map.get("More Items")) {
            map.set("More Items", [])
        }

        let cards = Array<JSX.Element>()

        for (let entry of Array.from(map.entries())) {
            let name = entry[0]
            let items = entry[1]

            cards.push(<Row key={name}><GroceryCard title={name} groceryItems={items} removeItem={this.removeItem} addItem={this.addItem} /></Row>)
        }

        return cards
    }

    saveCart = () => {
        this.groceryClient.saveCart(this.props.user as string, this.state.cart).then((result: boolean) => {
            this.setState({
                showChangesButton: false
            })
        }).catch(err => {console.log("Error saving cart", err)})
    }

    clearCart = () => {
        let emptyCart = GroceryApi.emptyCart()
        this.groceryClient.saveCart("todoparam", emptyCart).then((result: boolean) => {
            this.setState({
                showChangesButton: false,
                cart: emptyCart
            })
        }).catch(err => {console.log("Error saving cart", err)})
    }


    renderButtonRow(showChangesButton: boolean) {
        let changesButton: JSX.Element | undefined
        if (showChangesButton) {
            changesButton = <Button variant="warning" className="mx-auto halfWidth btn btn-block" onClick={() => this.saveCart()}>Click here to save changes</Button>
        } else {
            changesButton = undefined
        }

        return <Row >
            <div className="mb-3 halfWidth mx-auto">
                <Button className="mr-3 btn btn-block" variant="outline-secondary" onClick={() => this.clearCart()}>Clear Cart</Button>
                {changesButton}
            </div>
        </Row>
    }

    renderEmptyCartJumboTron() {
        return <Jumbotron className="bg-light width70 mx-auto">
        <h1>Empty Cart!</h1>
        <p className="mt-4">
            You have no items in your grocery cart.<br/>Go to the home 
            page and click the <Button variant="outline-primary" size="sm" className="mr-1">+Groceries</Button>button 
            on your favorite recipes
        </p>
      </Jumbotron>
    }

    renderFetchingCartJumboTron() {
        return <Jumbotron className="bg-light width70 mx-auto">
            <h1>One moment ....</h1>
        </Jumbotron>
    }

    render() {
        let emptyCart = this.state.cart.groceries.length === 0

        let cards = this.renderCards(this.state.cart)
        let buttonRow = undefined
        let emptyCartJumboTron = undefined

        let fetchingCartJumboTron = undefined



        if (this.state.fetchingCart) {
            fetchingCartJumboTron = this.renderFetchingCartJumboTron()
        } else {
            if (emptyCart) {
                emptyCartJumboTron = this.renderEmptyCartJumboTron()
            } else {
                buttonRow = this.renderButtonRow(this.state.showChangesButton)
            }
        }

        return (
            <React.Fragment>
                {fetchingCartJumboTron}
                {emptyCartJumboTron}
                {buttonRow}
                {cards}
                <Row>
                </Row>
            </React.Fragment>
        )
    }
}