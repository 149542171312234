import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image, Button, Card, Col, Row, ListGroup, Collapse } from 'react-bootstrap';
import { Recipe, Ingredient, GroceryClient } from './GroceryApi';
import { Link } from 'react-router-dom';
import './index.css'

interface RecipeState {
    ingredientsExpanded: boolean
    recipeExpanded: boolean
    groceriesExpanded: boolean
}

interface RecipeCardProps {
    recipe: Recipe,
    plusGroceryClick: (title: string, ingredients: Array<Ingredient>) => void
}

export default class RecipeCard extends React.Component<RecipeCardProps, RecipeState> {
    groceryClient: GroceryClient

    constructor(props: RecipeCardProps) {
        super(props)
        this.groceryClient = new GroceryClient()
        this.state = {
            "ingredientsExpanded": false,
            "recipeExpanded": false,
            "groceriesExpanded": false
        }
    }

    toggleIngredients() {
        this.setState({
            "ingredientsExpanded": !this.state.ingredientsExpanded
        })
    }
    toggleRecipe() {
        this.setState({
            "recipeExpanded": !this.state.recipeExpanded
        })
    }
    toggleGroceries() {
        this.setState({
            "groceriesExpanded": !this.state.groceriesExpanded
        })
    }

    renderImage() {
        if (this.props.recipe.image) {
            return (
                <Link to={{
                    pathname: `/recipes/${this.props.recipe.uid}`,
                    state: {"recipe": this.props.recipe}
                }}>
                    <Image className="img-fluid thumbnail" src={this.props.recipe.image} />
                </Link>
            )
        } else {
            return (
                <Link to={{
                    pathname: `/recipes/${this.props.recipe.uid}`,
                    state: {"recipe": this.props.recipe}
                }}>
                    <Image className="img-fluid thumbnail" src="https://pwf-grocery-images.s3.us-east-2.amazonaws.com/cropped-stock-image.jpg" />
                </Link>
            )
        }
    }


    renderIngredientsExpanded() {
        return (
            <Collapse in={this.state.ingredientsExpanded} >
                <div className="border-top">
                    <Row>
                        <Col>
                            <h3 className="border-bottom mt-4 ml-4 mr-4">Ingredients</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ul>
                                {
                                    this.props.recipe.ingredients.map( (ingredient: Ingredient) => {
                                        return <li key={ingredient.uid}>{ingredient.ingredient}</li>
                                    })
                                }
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        )
    }

    renderRecipeExpanded() {
        return (
            <Collapse in={this.state.recipeExpanded} >
                <div>
                    <Row>
                        <Col>
                            <h3 className="border-bottom mt-4">Recipe</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Make food.</p>
                        </Col>
                    </Row>
                </div>
            </Collapse>
        )
    }



    addToCart = () => {
        this.props.plusGroceryClick(this.props.recipe.title, this.props.recipe.ingredients)
    }

    renderButtonRow() {
        return (
            <ListGroup horizontal >
                <ListGroup.Item className="border-0 pl-0 pr-0 pt-1">
                    <Button variant="outline-primary" className="btn-block m-0 " size="sm" onClick={() => this.toggleIngredients()}>Ingredients</Button>
                </ListGroup.Item>
                <ListGroup.Item className="border-0 pl-0 pt-1">
                    <Button variant="outline-primary" className="ml-2 mt-0 mb-0 btn-block" size="sm" onClick={() => this.addToCart()}>+Groceries</Button>
                </ListGroup.Item>
            </ListGroup>
        )
    }

    render() {
        return (
                <Card className="gr-card mb-3 mx-auto" >
                    <Card.Body className="p-0"> 
                        <Row>
                            <Col xs="4" sm="4" md="3" lg="2" xl="2" >
                                    {this.renderImage()}
                            </Col>
                            <Col xs="8" sm="8" md="9" lg="10" xl="10" className="my-auto mb-3">
                                <Link to={{
                                    pathname: `/recipes/${this.props.recipe.uid}`,
                                    state: {"recipe": this.props.recipe}
                                }}>
                                    <h2 className="gr-card-title mt-1 mb-0">{this.props.recipe.title}</h2>
                                </Link>
                                <span className="gr-card-subtitle">{this.props.recipe.subtitle}</span>
                                {this.renderButtonRow()}
                            </Col>
                        </Row>
                        {this.renderIngredientsExpanded()}
                        {this.renderRecipeExpanded()}
                    </Card.Body>
                </Card>
        )
    }
}
