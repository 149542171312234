
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Badge } from 'react-bootstrap';
import { Recipe, GroceryClient } from './GroceryApi';
import * as GroceryApi from './GroceryApi'
import { Link, withRouter, } from 'react-router-dom';

interface RecipePageState {
    recipe: Recipe
}

class ViewRecipePage extends React.Component<any, RecipePageState> {
    groceryClient = new GroceryClient()

    constructor(props: any) {
        super(props)
        this.groceryClient = new GroceryClient()

        try {
            let js = props.location.state.recipe
            let r = GroceryApi.parseRecipe(js)

            this.state = {
                recipe: r
            }
        } catch (error) {
            this.state = {
                recipe: GroceryApi.emptyRecipe()
            }

        }
    }

    componentDidMount() {
        if (this.state.recipe.uid === "" || this.state.recipe.title === "") {
            console.log("Missing recipe, fetching the data...")
            let uid = this.props.match.params.uid
            this.groceryClient.get_recipe(uid).then(r => this.setState({recipe: r}))
        }
    }

    renderBadges(): Array<JSX.Element> {
        return this.state.recipe.tags.map(tag => <Badge variant="info" className="mr-1">{tag.value}</Badge>)
    }

    render() {
        // These come from the HOC component withRouter defined at the bottom
        const { url } = this.props.match;

        // Only render the edit recipe button if the logged in user is the author
        let userIsAuthor = this.props.user?.email === this.state.recipe.author
        let editButton
        if (userIsAuthor) {
            editButton = 
            <Link to={{
                pathname: `${url}/edit`,
                state: {
                    recipe: this.state.recipe, 
                    userIsAuthor: userIsAuthor
                }
            }}>
                <div className="mb-2 p-0"><button type="button" className="btn btn-outline-secondary btn-sm">Edit Recipe</button></div>
            </Link>
        } 

        let ingredientRows =  this.state.recipe.ingredients.map( ingredient => 
            <li key={ingredient.uid}> {ingredient.ingredient}</li>
        )

        let badges = this.renderBadges()

        return (
            <Row>
                <Col>
                    {editButton}
                    <small className="text-muted">Submitted by {this.state.recipe.author.split("@")[0]}</small>
                    <h1 className="display-2 mb-0">{this.state.recipe.title}</h1>
                    <h1><small className="text-muted mt-0">{this.state.recipe.subtitle}</small></h1>
                    <span>Tags: {badges}</span>


                    <h4 className="mt-5">Ingredients</h4>
                    <hr />
                    <ul>
                        {ingredientRows}
                    </ul>

                    <h4 className="mt-2">Recipe</h4>
                    <hr />
                    <div id="instructions" dangerouslySetInnerHTML={{ __html: this.state.recipe.instructions }}></div>
                </Col>
            </Row>
    )}
}


export default withRouter(ViewRecipePage)