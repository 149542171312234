
import React, { ChangeEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';



interface StringRowProps {
    row: StringRow
    rowChanged: (e: ChangeEvent<HTMLInputElement>) => void
    deleteRow: (id: string) => void
}

class StringRowComponent extends React.Component<StringRowProps> {
    render() {
        return <Form.Row as={Row} >
            <Form.Group as={Col} controlId={this.props.row.uid}  >
                <Form.Control required type="text" placeholder="..." defaultValue={this.props.row.value} onChange={e => this.props.rowChanged(e as any)} />
            </Form.Group>
            <Button className="h-25" variant="danger" onClick={e => this.props.deleteRow(this.props.row.uid)}>X</Button>
        </Form.Row>
    }
}

export interface StringRow {
    uid: string,
    value: string
}

interface StringTableProps {
    rows: Array<StringRow>
    onChange: (values: Array<StringRow>) => void
}

export default class StringTable extends React.Component<StringTableProps> {

    emptyStringRow(): StringRow {
        return {
            uid: uuidv4(),
            value: ""
        }
    }

    addRow = () => {
        let updatedRows = this.props.rows.concat(this.emptyStringRow())
        this.props.onChange(updatedRows)
    }

    deleteRow = (uid: string) => {
        let updatedRows = this.props.rows.filter(row => row.uid !== uid)
        this.props.onChange(updatedRows)
    }

    changeRow = (event: ChangeEvent<HTMLInputElement>) => {
        let uid: string = event.target.id
        let updatedRows: Array<StringRow> = this.props.rows.map(row => {
            if (row.uid === uid) {
                row.value = event.target.value
                return row
            } else {
                return row
            }
        })

        this.props.onChange(updatedRows)
    }

    render() {
        let rows = this.props.rows.map ( row => {
            return <StringRowComponent 
                row={row}
                key={row.uid}
                rowChanged={(e: ChangeEvent<HTMLInputElement>) => this.changeRow(e)} 
                deleteRow={(id: string) => this.deleteRow(id)}
            />
        })

        return (
            <React.Fragment>
                <Row>
                    <Col>{rows}</Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="outline-primary" onClick={() => this.addRow()} >Add Row</Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}