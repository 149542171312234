import React, { ChangeEvent } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Form, Row, Col, Button } from 'react-bootstrap';

interface CropImageProps {
    croppedImage: (base64Image: string) => void
}

interface CropImageState {
    crop: Crop
    croppedImageUrl: string | undefined
    src: string | undefined
    imageRef: HTMLImageElement | undefined
    finished: boolean
}

export default class CropImageComponent extends React.Component<CropImageProps, CropImageState> {
    constructor(props: CropImageProps) {
        super(props)
        this.state = {
            src: undefined,
            crop: {
                unit: 'px',
                width: 130,
                aspect: 1 / 1,
            },
            croppedImageUrl: undefined,
            imageRef: undefined,
            finished: false
        }
    }

  onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ 
            src: reader.result as string
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image: HTMLImageElement) => {
    this.setState({
        imageRef: image
    })
  }

  onCropComplete = (crop: Crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: Crop, percentCrop: Crop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop: Crop) {
    if (this.state.imageRef && crop.width && crop.height) {
        const croppedImageUrl = await this.getCroppedImgAsThumbnail(
            this.state.imageRef,
            crop,
            'newFile.jpeg'
        );
        this.setState({ 
            croppedImageUrl 
        });
    }
  }

  getCroppedImg(image: HTMLImageElement, crop: Crop, fileName: string): Promise<string> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width!;
    canvas.height = crop.height!;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width! * scaleX,
      crop.height! * scaleY,
      0,
      0,
      crop.width!,
      crop.height!
    );

    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');

    return new Promise((resolve, reject) => {
        resolve(base64Image)
    })
  }

  getCroppedImgAsThumbnail(image: HTMLImageElement, crop: Crop, fileName: string): Promise<string> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width!;
    canvas.height = crop.height!;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width! * scaleX,
      crop.height! * scaleY,
      0,
      0,
      crop.width!,
      crop.height!
    );


    let thumbnailSize = 130
    if (canvas.width <= 130 && canvas.height <= 130) {
      // we're already done
      // As Base64 string
      const base64Image = canvas.toDataURL('image/jpeg');
      return new Promise((resolve, reject) => {
          resolve(base64Image)
      })
    } else {
      // This section is taken from Eyal c answer here: https://stackoverflow.com/questions/19262141/resize-image-with-javascript-canvas-smoothly
      let curImageDimensions = {
        width: Math.floor(canvas.width),
        height: Math.floor(canvas.height)
      };

      let halfImageDimensions = {
          width: -1,
          height: -1
      };

      // Quickly reduce the dize by 50% each time in few iterations until the size is less then
      // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
      // created with direct reduction of very big image to small image
      while (curImageDimensions.width * 0.5 > thumbnailSize) {
          // Reduce the resizing canvas by half and refresh the image
          halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
          halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

          ctx?.drawImage(canvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
              0, 0, halfImageDimensions.width, halfImageDimensions.height);

          curImageDimensions.width = halfImageDimensions.width;
          curImageDimensions.height = halfImageDimensions.height;
      }

      // Now do final resize for the resizingCanvas to meet the dimension requirments
      // directly to the output canvas, that will output the final image
      let outputCanvas: HTMLCanvasElement = document.createElement('canvas');
      let outputCanvasContext = outputCanvas.getContext("2d");

      outputCanvas.width = thumbnailSize;
      outputCanvas.height = thumbnailSize;

      outputCanvasContext?.drawImage(canvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
          0, 0, thumbnailSize, thumbnailSize)


      // As Base64 string
      const base64Image = outputCanvas.toDataURL('image/jpeg');
      return new Promise((resolve, reject) => {
          resolve(base64Image)
      })
    }
  }

  onDone = () => {
    // Send the cropped image data to the caller
    if (this.state.croppedImageUrl) {
      this.props.croppedImage(this.state.croppedImageUrl)
    }

    // Hide the elements
    this.setState({
      finished: true
    })
  }


  renderReactCrop() {
    if (this.state.src && !this.state.finished) {
      return (
      <React.Fragment>
        <Row>
          <Col>
            {this.state.src && (
              <ReactCrop
                src={this.state.src}
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={() => this.onDone()}>Done</Button>
          </Col>
        </Row>
      </React.Fragment>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <Row className="mb-3">
          <Col>
            <Form>
              <Form.File 
                id="custom-file"
                label="Upload thumbnail image"
                onChange={this.onSelectFile}
                custom
              />
            </Form>
          </Col>
        </Row>
        {this.renderReactCrop()}
      </React.Fragment>
    );
  }
}

