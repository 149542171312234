import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Modal, Button, NavDropdown } from 'react-bootstrap';
import RecipeCard from './RecipeCard';
import { GroceryClient, Recipe, Ingredient, GroceryCartItem } from './GroceryApi';
import IngredientRows from './IngredientRows';
import { v4 as uuidv4 } from 'uuid';


interface RecipePageProps {
    contextualNavItems: (nav: JSX.Element) => void
}
interface RecipePageState {
    recipes: Array<Recipe>,
    show: boolean,
    modalIngredients: Array<Ingredient>,
    modalTitle: string,
    tagFilter: Array<string>
}

export default class RecipePage extends React.Component<RecipePageProps, RecipePageState> {
    groceryClient: GroceryClient

    constructor(props: RecipePageProps) {
        super(props)
        this.groceryClient = new GroceryClient()
        this.state = {
            recipes: [],
            show: false,
            modalIngredients: [],
            modalTitle: "",
            tagFilter: []
        }
    }

    toggleTagFilter = (tag: string) => {
        let newTagFilter: Array<string> = []
        if (tag === "All") {
            this.setState({
                tagFilter: []
            })
        } else {
            if (this.state.tagFilter.includes(tag)) {
                newTagFilter = this.state.tagFilter.filter(t => t !== tag)
                this.setState({
                    tagFilter: newTagFilter
                })
            } else {
                newTagFilter = this.state.tagFilter.concat(tag)
                this.setState({
                    tagFilter: newTagFilter
                })
            }
        }

        this.props.contextualNavItems(this.renderContextualNav(this.state.recipes, newTagFilter))
    }


    renderContextualNav(recipes: Array<Recipe>, tagFilter: Array<string>): JSX.Element {
            let tagSet: Set<string> = new Set(recipes.flatMap(recipe => recipe.tags.map(x => x.value)))
            let tagArray: Array<string> = Array.from(tagSet.values())

            let dropItems = tagArray.map(tag => {
                let text: JSX.Element | undefined = undefined
                if (tagFilter.includes(tag)) {
                    text =  <React.Fragment>
                                <span>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                                    </svg>
                                    {tag}
                                </span>
                            </React.Fragment>
                } else {
                    text = <p>{tag}</p>
                }

                return <NavDropdown.Item onClick={() => this.toggleTagFilter(tag)}>{text}</NavDropdown.Item>
            })

            let contextualNav: JSX.Element = <NavDropdown title="Filter" id="basic-nav-dropdown">
                                    {dropItems}
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => this.toggleTagFilter("All")} >All</NavDropdown.Item>
                                </NavDropdown>

            return contextualNav
    }

    componentDidMount() {
        // This is where a component should fetch data
        this.groceryClient.get_recipes().then(r => {

            this.setState({
                recipes: r,
            })

            this.props.contextualNavItems(this.renderContextualNav(r, this.state.tagFilter))
        })
    }

    plusGroceryClick = (title: string, ingredients: Array<Ingredient>) => {
        let ingredientsWithUid = ingredients.map(ing => {
            if (ing.uid) {
                return ing
            } else {
                return {
                    uid: uuidv4(),
                    ingredient: ing.ingredient
                }
            }
        })

        this.setState({
            modalTitle: title,
            modalIngredients: ingredientsWithUid,
            show: true
        })
    }

    groceryChange = (ingredients: Array<Ingredient>) => {
        console.log("Ingredient change", ingredients)
        this.setState({
            modalIngredients: ingredients
        })
    }

    modalHide = () => {
        this.setState({
            show: false,
            modalIngredients: [],
            modalTitle: ""
        })
    }

    addToCart = () => {
        let items = this.state.modalIngredients.map(ingredient => {
            return new GroceryCartItem(this.state.modalTitle, ingredient.ingredient as string, false)
        })
        this.groceryClient.addToCart("thisisafakeresource", items).then(result => {
            this.modalHide()
        })
    }

    render() {
        const recipeColumns: Array<JSX.Element> = this.state.recipes
        .filter( (r: Recipe) => {
            if (this.state.tagFilter.length === 0) {
                // Don't apply a filter
                return true
            } else {
                // Filter for tags
                let results = this.state.tagFilter.map(tag => {
                    let recipeTags = r.tags.map(x => x.value)
                    return recipeTags.includes(tag)
                })
                return results.includes(true)
            } 
        })
        .map( (r: Recipe) => {
            return (
                <Col md="12" sm="12" key={r.uid}>
                    <RecipeCard recipe={r} plusGroceryClick={this.plusGroceryClick} />
                </Col>
            )
        })

        return (
            <React.Fragment>
                <Row>
                    {recipeColumns}
                </Row>
                <Modal show={this.state.show} onHide={this.modalHide} >
                    <Modal.Header>
                        <Modal.Title>
                            Add the following to your cart:
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <IngredientRows ingredients={this.state.modalIngredients} onChange={this.groceryChange} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => this.addToCart()}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}