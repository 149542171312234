

const awsmobile = {
    "aws_project_region": "us-east-2",

    aws_user_pools: 'enable',
    aws_user_pools_id: 'us-east-2_LDsHMPqgO',
    aws_user_pools_mfa_type: 'OFF',
    aws_user_pools_web_client_id: '40ddlns6q22oo035sbmhan6pif',
    aws_user_settings: 'enable'
};


export default awsmobile;
