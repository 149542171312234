import React, { FormEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { GroceryClient, Recipe } from './GroceryApi';
import RecipeEditor from './RecipeEditor';
import * as GroceryApi from './GroceryApi'

interface NewRecipeProps {
    user: string
}

export default class NewRecipePage extends React.Component<NewRecipeProps> {
    groceryClient: GroceryClient

    constructor(props: NewRecipeProps) {
        super(props)
        this.groceryClient = new GroceryClient()
    }


    handleSubmit = (event: FormEvent<HTMLElement>, recipe: Recipe) => {
        // The form will do the proper validation for us, then call this function

        // Stop the proper submit. We'll call API and handle the logic here
        event.preventDefault();
        event.stopPropagation();

        Auth.currentSession().then((u: CognitoUserSession) => {
            return u.getIdToken().getJwtToken()
        }).then(jwtToken => {
            return this.groceryClient.put_recipe(jwtToken, recipe) 
        }).then(success => {
            if (success === true) {
                window.location.replace("/")
            } else {
                alert("Error saving recipe. Tell Phil this happened.")
            }
        })
    }

    render() {
        // Initialize page with an empty recipe and an empty ingredient
        let emptyRecipe = GroceryApi.emptyRecipe()
        emptyRecipe.ingredients.push(GroceryApi.emptyIngredient())
        emptyRecipe.author = this.props.user

        return <RecipeEditor 
                user={this.props.user}
                recipe={emptyRecipe}
                fetchData={false}
                submit={this.handleSubmit}
            />
    }
}