import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { GroceryClient, Recipe } from './GroceryApi';
import RecipeEditor from './RecipeEditor';
import { FormEvent } from 'react';
import * as GroceryApi from './GroceryApi'
import { withRouter, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';



interface EditRecipePageState {
    redirect: JSX.Element | undefined
}

class EditRecipePage extends React.Component<any, EditRecipePageState> {
    groceryClient = new GroceryClient()
    recipe: Recipe
    fetchData: boolean

    constructor(props: any) {
        super(props)

        this.state = {
            redirect: undefined
        }

        try {
            // The recipe should be passed by a link component
            // On direct links it won't be there, we'll have to fetch the data
            let js = props.location.state.recipe
            this.recipe = GroceryApi.parseRecipe(js)
            this.fetchData = false
        } catch (error) {
            this.recipe = GroceryApi.emptyRecipe()
            this.recipe.uid = this.props.match.params.uid
            this.fetchData = true
        }
    }

    submit = (event: FormEvent<HTMLElement>, recipe: Recipe) => {
        // The form will do the proper validation for us, then call this function

        // Stop the proper submit. We'll call API and handle the logic here
        event.preventDefault();
        event.stopPropagation();

        Auth.currentSession().then((u: CognitoUserSession) => {
            return u.getIdToken().getJwtToken()
        }).then(jwtToken => {
            return this.groceryClient.put_recipe(jwtToken, recipe) 
        }).then(success => {
            if (success === true) {
                this.setState({
                    redirect: <Redirect to={{
                            pathname: `/recipes/${recipe.uid}`,
                            state: { recipe: recipe }
                        }}
                        />
                })
            } else {
                alert("Error saving recipe. Tell Phil this happened.")
            }
        })
    }

    render() {
        let redirect = this.state.redirect

        return <React.Fragment>
            {redirect}
            <RecipeEditor 
                user={this.props.user}
                recipe={this.recipe}
                fetchData={this.fetchData}
                submit={this.submit}
            />
        </React.Fragment>
    }
}

export default withRouter(EditRecipePage)