import React, { ChangeEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Ingredient } from './GroceryApi';
import * as GroceryApi from './GroceryApi'

interface IngredientRowProps {
    uid: string
    ingredient: string | undefined
    ingredientChanged: (e: ChangeEvent<HTMLInputElement>) => void
    deleteRow: (id: string) => void
}

class IngredientRow extends React.Component<IngredientRowProps> {
    render() {
        return <Form.Row as={Row} >
            <Form.Group as={Col} controlId={this.props.uid}  >
                <Form.Control required type="text" placeholder="Passata" defaultValue={this.props.ingredient} onChange={e => this.props.ingredientChanged(e as any)} />
            </Form.Group>
            <Button className="h-25" variant="danger" onClick={e => this.props.deleteRow(this.props.uid)}>X</Button>
        </Form.Row>
    }
}

interface IngredientRowsProps {
    ingredients: Array<Ingredient>
    onChange: (ingredients: Array<Ingredient>) => void
}

export default class IngredientRows extends React.Component<IngredientRowsProps> {

    addIngredientRow = () => {
        let updatedIngredients = this.props.ingredients.concat(GroceryApi.emptyIngredient())
        this.props.onChange(updatedIngredients)
    }

    deleteRow = (uid: string) => {
        let updatedIngredients = this.props.ingredients.filter(ingredient => ingredient.uid !== uid)
        this.props.onChange(updatedIngredients)
    }

    ingredientChange = (event: ChangeEvent<HTMLInputElement>) => {
        let uid: string = event.target.id
        let updatedIngredients: Array<Ingredient> = this.props.ingredients.map(ing => {
            if (ing.uid === uid) {
                    ing.ingredient = event.target.value
                return ing
            } else {
                return ing
            }
        })

        this.props.onChange(updatedIngredients)
    }

    render() {
        let ingredientRows = this.props.ingredients.map ( ing => {
            return <IngredientRow 
                uid={ing.uid}
                key={ing.uid}
                ingredient={ing.ingredient} 
                ingredientChanged={(e: ChangeEvent<HTMLInputElement>) => this.ingredientChange(e)} 
                deleteRow={(id: string) => this.deleteRow(id)}
            />
        })

        return (
            <React.Fragment>
                <Row>
                    <Col>{ingredientRows}</Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="outline-primary" onClick={() => this.addIngredientRow()}>Add Ingredient</Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}