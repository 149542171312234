import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import RecipePage from './RecipePage';
import NewRecipePage from './NewRecipePage';
import ViewRecipePage from './ViewRecipePage';
import CartPage from './CartPage';

import Amplify, { Auth } from 'aws-amplify';
import { AmplifyAuthenticator} from '@aws-amplify/ui-react';
import amplify_config from './amplify-config';
import { AuthState } from '@aws-amplify/ui-components'

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Container, Form, Nav, Navbar, Modal } from 'react-bootstrap';
import EditRecipePage from './EditRecipePage';

Amplify.configure(amplify_config);

interface BootstrapAppProps {
}

interface BootstrapAppState {
    show: boolean
    user: User | undefined
    contextualNavItems: JSX.Element | undefined
}

interface User {
    email?: string
}

export default class BootstrapApp extends React.Component<BootstrapAppProps, BootstrapAppState> {
    constructor(props: BootstrapAppProps) {
        super(props)
        this.state = {
            show: false,
            user: {
                "email": undefined 
            },
            contextualNavItems: undefined
        }
    }

    componentDidMount() {
        if (!this.state.user?.email) {
            // On page refresh we lose the current state, check if a user is logged in already
            Auth.currentUserInfo().then( currentUserInfo => {
                let user = currentUserInfo?.attributes.email
                if (user) {
                    this.setState({
                        user: {
                            email: user
                        }
                    })
                } else {
                    console.log("No user, setting none")
                    this.setState({
                        user: undefined
                    })

                }
            })
        }

    }

    // This function shows the log in Modal
    signInClick = () => {
        this.setState({
            show: !this.state.show
        })
    }

    // Performs a log out
    signOutUser = () => {
        Auth.signOut()
        this.setState({
            "user": {
                "email": undefined
            }
        })
    }

    // A hook for authentication state changes. 
    handleAuthStateChange = (nextAuthState: AuthState, data?: any) => {
        let user = data?.attributes.email
        if (user) {
            this.setState({
                "user": {
                    "email": user
                }
            })
        }

        if (nextAuthState === "signedin") {
            this.setState({
                "show": false
            })
        }
    }


    renderCartButton(user: string| undefined) {
        if (typeof user === "string") {
            return  <LinkContainer to={{
                pathname:"/cart"
            }}>
                        <Button variant="outline-light" className="mr-2">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-bag mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"/></svg>
                            <span className="align-middle">Cart</span>
                        </Button>
                    </LinkContainer>
        } else {
            return <Button variant="outline-light" className="mr-2" onClick={this.signInClick}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-bag mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"/></svg>
                        <span className="align-middle">Cart</span>
                    </Button>
        }
    }

    getContextualNavItems = (nav: JSX.Element) => {
        this.setState({
            contextualNavItems: nav
        })
    }

    render() {
        // These elements depend on a user being signed in
        let button
        let newRecipeLink 
        
        // Get the user email, or empty string. This is for the NewRecipePage props, this is ugly
        // and should be handled better as part of ProtectRoute's configuration
        let username = this.state.user?.email ? this.state.user.email : ""

        if (this.state.user?.email && this.state.user.email !== "none") {
            button = <Button variant="outline-light" onClick={ this.signOutUser }>Sign Out</Button>
            newRecipeLink = <LinkContainer to="/new"><Nav.Link>New</Nav.Link></LinkContainer>
        } else {
            button = <Button variant="outline-light" onClick={ this.signInClick }>Sign In</Button>
            newRecipeLink = <Nav.Link onClick={() => this.signInClick()}>New</Nav.Link>
        }

        let cartButton = this.renderCartButton(this.state.user?.email)

        return (
            <React.Fragment>
                <Router>

                <Navbar bg="primary" variant="dark" className="mb-4" expand="lg">
                    <Navbar.Brand>Meal Planning Simplified</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <LinkContainer to="/">
                                <Nav.Link>Home</Nav.Link>
                            </LinkContainer>
                            {newRecipeLink}
                            {this.state.contextualNavItems}
                        </Nav>
                        <Form inline>
                            {cartButton}
                            {button}
                        </Form>
                    </Navbar.Collapse>
                </Navbar>

                <Modal show={this.state.show} onHide={this.signInClick} >
                    <Modal.Body>
                        <AmplifyAuthenticator handleAuthStateChange={this.handleAuthStateChange}/>
                    </Modal.Body>
                </Modal>

                <Container id="content">
                    <Switch>
                        <Route exact path="/" render={() => <RecipePage contextualNavItems={this.getContextualNavItems}  />} />
                        <Route exact path="/new" render={() => <NewRecipePage user={username} />} />
                        <Route exact path="/cart" render={(props) => <CartPage user={this.state.user?.email} {...props}  />} />
                        <Route exact path="/recipes/:uid" render={(props) => <ViewRecipePage {...props} user={this.state.user} />} />
                        <Route exact path="/recipes/:uid/edit" render={(props) => <EditRecipePage user={this.state.user?.email} {...props}  />} />
                        <Route component={RecipePage} />
                    </Switch>
                </Container>
                </Router>
            </React.Fragment>
        )
    }
}
